/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RoutingUIError } from './RoutingUIError';
import { RoutingRequestOverview } from '../RoutingRequestOverview';

const ErrorMessageStyles = css`
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #00000040;
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 0;

  > h2 {
    margin-top: 0;
  }

  > p {
    margin-bottom: 1.6rem;
  }
`;

const getHeadline = (errorName?: string) => {
  if (!errorName) {
    return 'Error';
  }

  switch (errorName) {
    case 'ROUTING_DECISION_ERROR':
      return 'Routing Decision Error';
    case 'ROUTING_REQUEST_ERROR':
      return 'Routing Request Error';
    case 'INFORMATION':
      return null;
    default:
      return 'Error';
  }
};

const ErrorMessage = ({ error }: { error: RoutingUIError | Error }) => {
  if (error instanceof RoutingUIError) {
    return (
      <p data-testid="error-message-with-link">
        {error.message}
        {error.link && (
          <>
            {' '}
            <a href={error.link.href} target="_blank" rel="noreferrer">
              View the related {error.link.name}
            </a>
            .
          </>
        )}
      </p>
    );
  }

  return <p>{error.message}</p>;
};

export const ContactSupport = ({ error }: { error: RoutingUIError | Error | undefined }) => {
  if (!error) {
    return null;
  }

  const headline = getHeadline(error.name);

  return (
    <>
      {error instanceof RoutingUIError && error.context && <RoutingRequestOverview {...error.context} />}
      <div css={ErrorMessageStyles} data-testid="error-message-wrapper">
        {headline && <h2>{headline}</h2>}
        <ErrorMessage error={error} />

        {error.name !== 'INFORMATION' && (
          <p>
            For help, please reach out to MCP Platform Support on Slack in the{' '}
            <a href="https://cimpress.slack.com/archives/C7CNT9E2Z" target="_blank" rel="noreferrer">
              #mcp-platform-support channel
            </a>
            .
          </p>
        )}
      </div>
    </>
  );
};
