/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { get } from 'lodash';
import { Button, Copy } from '@cimpress/react-components';
import { Environment, FailedDecision, Item, SuccessfulDecision } from '../types';
import { OverviewDropdown } from './OverviewDropdown';
import { useContext } from 'react';
import { AccessContext } from './DecisionViewer';
import { useGetPreview } from '../hooks/useGetPreview';
import { copyButtonStyles } from '../sharedStyles';
import { RetryContext } from './Retry/RetryContext';
import { isItemSuspendedInRouting } from '../helpers/item';

export type RoutingRequestOverviewProps = {
  item?: Item | undefined;
  routingDecision?: SuccessfulDecision | FailedDecision | undefined;
  decisionLink?: string;
  showDecisionLinks?: boolean;
};

const routingRequestOverviewStyles = css`
  border: 1px solid #00000040;
  box-sizing: border-box;
  margin-bottom: 32px;

  h2 {
    font-weight: 700;
    font-size: 1.7rem;
    margin-top: 0;
    line-height: 17.7px;
  }
`;

const overviewGridStyles = css`
  display: grid;
  grid-template-columns: 140px 1fr;
  line-height: 19.07px;
  font-size: 1.4rem;
  gap: 0 1.6rem;
  word-break: break-word;

  div:nth-of-type(odd) {
    text-transform: uppercase;
    font-weight: 600;
    color: #0000006e;
  }
`;

const overviewWrapperStyles = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  container-type: inline-size;
`;

const contentWrapperStyles = css`
  display: flex;
  gap: 3rem;
  padding: 1.6rem 0 1.6rem 1.6rem;

  @container (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const productPreviewStyles = css`
  width: 230px;
  display: flex;
  justify-content: space-evenly;

  img {
    object-fit: contain;
    max-width: 100%;
  }
`;

const dropdownWrapperStyles = css`
  display: grid;
  grid-template-columns: 1fr min-content;
  margin-top: 0.8rem;
`;

const retryButtonStyles = css`
  margin-right: 0.8rem;
`;

const getRoutingUiBaseUrl = (environment: Environment) => {
  if (environment === 'int') {
    return 'https://int-routing-configuration.cimpress.io';
  }

  return 'https://routing-configuration.cimpress.io';
};

export const RoutingRequestOverview = ({
  item,
  routingDecision,
  decisionLink,
  showDecisionLinks,
}: RoutingRequestOverviewProps) => {
  const { environment, accessToken } = useContext(AccessContext);
  const { retryState, setRetryState, retryRoutingRequest, pickedOption } = useContext(RetryContext);
  const routingConfiguration = routingDecision?._links?.routingConfiguration;

  const { data: productPreview } = useGetPreview({
    accessToken,
    documentReferenceUri: item?.documentReferenceUrl ?? undefined,
    height: 162,
  });

  const isRetrying = retryState === 'PENDING';
  const routingRequestId = get(item, ['_links', 'routingRequest', 'name']) as string | undefined;
  const itemLink = get(item, ['_links', 'self']);

  const handleRetry = () => {
    if (!retryRoutingRequest || !routingRequestId) {
      return;
    }

    setRetryState('PENDING');

    retryRoutingRequest.mutate({
      environment,
      accessToken,
      routingRequestId,
      selections: pickedOption && itemLink && [{ ...pickedOption, itemLink }],
    });
  };

  if (!item) {
    return null;
  }

  const getButtonText = () => {
    if (pickedOption) {
      if (isRetrying) {
        return 'Submitting...';
      }
      return 'Submit';
    }

    if (isRetrying) {
      return 'Retrying...';
    }

    return 'Retry';
  };

  return (
    <div css={routingRequestOverviewStyles} data-testid="routing-request-overview">
      <div css={overviewWrapperStyles}>
        <div css={contentWrapperStyles}>
          {productPreview?.previewUrl && (
            <div css={productPreviewStyles}>
              <img src={productPreview.previewUrl} alt={`Preview of ${item.merchantProductName}`} />
            </div>
          )}
          <div>
            <h2>{item.merchantProductName}</h2>
            <div css={overviewGridStyles}>
              <div>Item ID</div>
              <div>
                {item.itemId}{' '}
                <Copy value={item.itemId} css={copyButtonStyles}>
                  <></>
                </Copy>
              </div>
              <div>SKU</div>
              <div>
                {item.orderedSkuCode}{' '}
                <Copy value={item.orderedSkuCode} css={copyButtonStyles}>
                  <></>
                </Copy>
              </div>
              <div>Buyer</div>
              <div>{item.merchantId}</div>
              <div>Quantity</div>
              <div>{item.quantity}</div>
              {item.productAttributes && (
                <>
                  <div>Details</div>
                  <div>{item.productAttributes}</div>
                </>
              )}
              {routingConfiguration && (
                <>
                  <div>Configuration</div>
                  <div>
                    <a
                      href={`${getRoutingUiBaseUrl(environment)}/configurations/${routingConfiguration?.name}`}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="routing-config-link"
                    >
                      View configuration
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div css={dropdownWrapperStyles}>
          {isItemSuspendedInRouting(item) && (
            <div css={retryButtonStyles}>
              <Button variant="default" disabled={isRetrying} onClick={() => handleRetry()}>
                {getButtonText()}
              </Button>
            </div>
          )}
          <OverviewDropdown
            showDecisionLinks={showDecisionLinks}
            decisionLink={decisionLink}
            routingDecision={routingDecision}
          />
        </div>
      </div>
    </div>
  );
};
