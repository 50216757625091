import { useQuery } from 'react-query';
import {
  GetDecisionFromUrlParams,
  GetDecisionParams,
  GetDecisionsParams,
  getDecision,
  getDecisionFromUrl,
  getDecisions,
} from '../clients/orca';
import { FailedDecision, SuccessfulDecision } from '../types';
import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';

export const useGetDecisions = (props: GetDecisionsParams) => {
  return useQuery<SuccessfulDecision[] | FailedDecision[], Error>(['getDecisions', props.params], () =>
    getDecisions(props),
  );
};

export const useGetDecision = (props: GetDecisionParams) => {
  return useQuery<SuccessfulDecision | FailedDecision, Error>(['getDecision', props.decisionId], () =>
    getDecision(props),
  );
};

export const useGetDecisionFromUrl = (props: GetDecisionFromUrlParams) => {
  return useQuery<SuccessfulDecision | FailedDecision, RoutingUIError | Error>(
    ['getDecisionFromUrl', props.decisionLink],
    () => getDecisionFromUrl(props),
  );
};
