import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';
import { getErrorMessage } from '../helpers/errorStatus';
import { determineProductAttributes } from '../helpers/productAttributes';
import { Environment, Item } from '../types';
import { fetchWithAuth } from './fetchWithAuth';
import { getMcpProductByMcpSku } from './productIntroduction';

const INT_BASE_URL = 'https://int-item-service.commerce.cimpress.io';
const PROD_BASE_URL = 'https://item-service.commerce.cimpress.io';

const getBaseUrl = (environment: Environment): string => (environment === 'int' ? INT_BASE_URL : PROD_BASE_URL);

export type GetItemByIdParams = {
  environment: Environment;
  accessToken: string;
  decisionLink: string;
  itemId: string | undefined;
};

export const getItemByItemId = async ({
  environment,
  accessToken,
  decisionLink,
  itemId,
}: GetItemByIdParams): Promise<Item> => {
  if (!itemId) {
    throw new RoutingUIError({
      name: 'ROUTING_DECISION_ERROR',
      message: 'There is no item ID on the routing decision.',
      link: {
        href: decisionLink,
        name: 'Routing decision link',
      },
    });
  }

  const path = `${getBaseUrl(environment)}/v1/items/${itemId}`;
  const response = await fetchWithAuth({ path, accessToken });

  if (!response.ok) {
    throw new RoutingUIError({
      name: 'ERROR',
      message: getErrorMessage({ erroredItem: `item ID ${itemId} from the Item Service`, statusCode: response.status }),
      link: { href: path, name: 'Item service link' },
    });
  }

  const item = (await response.json()) as Item;

  try {
    const productAttributeResponse = await getMcpProductByMcpSku({ accessToken, mcpSku: item.orderedSkuCode });
    const productAttributes = determineProductAttributes(productAttributeResponse);

    if (productAttributes) {
      item.productAttributes = productAttributes;
    }
  } catch (error) {
    // Swallow the error since while it's nice to have this information, we can still show the rest of
    // the decision information without it
  }

  return item;
};
