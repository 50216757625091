/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const wrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #00000040;
  box-sizing: border-box;
  overflow-y: auto;

  > h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.7rem;
    font-weight: 700;
    margin: 0 0 1.6rem 0;
  }
`;

export const copyButtonStyles = css`
  background: none;

  > div {
    top: 0;
  }
`;
