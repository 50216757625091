/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FulfillmentOption } from './FulfillmentOption/FulfillmentOption';
import { InvalidConsideredOption, isValidOption, Option } from '../types';
import { UseQueryResult } from 'react-query';
import { wrapper } from '../sharedStyles';

const fulfillmentOptionWrapper = css`
  padding: 1.6rem 0 0;

  > h3 {
    padding: 0 1.6rem;
  }

  .scrollBox {
    box-sizing: border-box;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
`;

const scrollBoxWrapperStyles = css`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  overflow-y: auto;
`;

export const FulfillmentOptions = ({
  options,
  isItemSuspendedInRouting,
}: {
  options: UseQueryResult<Option | InvalidConsideredOption, Error>[];
  isItemSuspendedInRouting: boolean;
}) => {
  if (!options || !options.length || options.some((option) => !option.isFetched)) {
    return null;
  }

  const optionData = options.reduce(
    (acc, option) => {
      if (!option.error && option.data) {
        acc.push(option.data);
      }

      return acc;
    },
    [] as (Option | InvalidConsideredOption)[],
  );

  const sortedOptions = optionData.sort((a, b) => {
    if (isValidOption(a) && a.rank && isValidOption(b) && b.rank) {
      return Number(a.rank) - Number(b.rank);
    } else if (isValidOption(a) && a.rank) {
      return -1;
    } else if (isValidOption(b) && b.rank) {
      return 1;
    } else {
      // Sort by alphanumeric SKU if we don't have a rank to sort by (i.e., invalid options)
      return a.referenceId.localeCompare(b.referenceId, 'en', {
        numeric: true,
        sensitivity: 'base',
      });
    }
  });

  return (
    <div css={[wrapper, fulfillmentOptionWrapper]} data-testid="fulfillment-options">
      <h3>Fulfillment Options</h3>
      <div css={scrollBoxWrapperStyles}>
        <ul className="scrollBox">
          {sortedOptions.map((option) => (
            <li key={`${option.optionId}-list-item`} data-testid="option-list-item">
              <FulfillmentOption option={option} isItemSuspendedInRouting={isItemSuspendedInRouting} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
