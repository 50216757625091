/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { get } from 'lodash';
import { useContext } from 'react';
import { Accordion, Alert, Button } from '@cimpress/react-components';
import { ShipmentPlanViewer } from '@cimpress-technology/shipment-planner-component';
import { InvalidConsideredOption, Option } from '../../types';
import { FulfillmentOptionHeader } from './FulfillmentOptionHeader';
import { AccessContext } from '../DecisionViewer';
import { FormattedAlertMessages } from './FormattedAlertMessages';
import { RetryContext } from '../Retry/RetryContext';

const accordionHeaderStyles = css`
  border-color: #c8cbd0;
  border-radius: 0;
  container-type: inline-size;

  &.accordion-open {
    [role='button'] {
      border-bottom: 1px solid #c8cbd0;
    }
  }

  [role='button'] {
    background-color: #ffffff;
    padding: 0;
    border-radius: 0;

    > div {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
`;

const fulfillmentOptionBodyStyles = css`
  margin-bottom: 10px;
  container-type: inline-size;

  h3 {
    font-size: 13px;
    font-weight: 700;
    line-height: 17.7px;
    margin-bottom: 8px;
    margin-top: 0;
  }

  p {
    font-size: 13px;
    line-height: 17.7px;
  }
`;

const productInfoGridStyles = css`
  display: grid;
  grid-template-columns: 24% 1fr;
  gap: 0 1.6rem;
  line-height: 17.7px;
  font-size: 1.3rem;

  @container (max-width: 800px) {
    gap: 0.8rem 1.6rem;
    align-items: center;
  }

  @container (max-width: 625px) {
    grid-template-columns: 100%;
    margin-bottom: 1.6rem;
    gap: 0;

    > div:not:first-child {
      max-width: calc(100% - 1.6rem);
    }

    > div:nth-of-type(odd) {
      margin-bottom: 1.6rem;
    }
  }
`;

const noShipmentPlanStyles = css`
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 17.7px;
    margin-bottom: 0.8rem;
    margin-top: 0;
  }
`;

const headerStyles = css`
  line-height: 17.7px;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #0000006e;
  font-weight: 600;
  word-break: break-word;
`;

const headlineGrid = css`
  display: grid;
  grid-template-columns: 24% 1fr;
  gap: 0 1.6rem;
  line-height: 17.7px;
  font-size: 1.3rem;
  grid-column: 1 / span 2;

  .pickMeButton {
    justify-self: flex-end;
  }

  @container (max-width: 625px) {
    max-width: 100%;
    grid-column: 1;
  }
`;

export const FulfillmentOption = ({
  option,
  isItemSuspendedInRouting,
}: {
  option: Option | InvalidConsideredOption | undefined;
  isItemSuspendedInRouting: boolean;
}) => {
  const { accessToken, environment } = useContext(AccessContext);
  const { pickedOption, setPickedOption } = useContext(RetryContext);

  if (!option) {
    return null;
  }

  const shipmentPlans = get(option, ['_links', 'shipmentPlan']);
  const problem = get(option, ['problem']);
  const productConfiguraton = get(option, ['_links', 'productConfiguration']);
  const fulfillmentConfiguration = get(option, ['_links', 'fulfillmentConfiguration', 'href']) as string | undefined;
  const isPickedOption =
    fulfillmentConfiguration === pickedOption?.chosenFCUrl &&
    productConfiguraton?.href === pickedOption?.chosenProductUrl;

  return (
    <Accordion
      key={`${option.optionId}-accordion`}
      title={<FulfillmentOptionHeader option={option} />}
      css={accordionHeaderStyles}
      data-testid="fulfillment-option-accordion"
    >
      {problem && problem.causes && problem.causes.length > 0 && (
        <Alert
          title={problem.causes.length === 1 ? 'Reason' : 'Reasons'}
          message={<FormattedAlertMessages messages={problem.causes} />}
          dismissible={false}
        />
      )}
      <div css={fulfillmentOptionBodyStyles}>
        <div css={productInfoGridStyles} data-testid="option-detail-grid">
          <div css={headlineGrid}>
            <h3>Details</h3>
            {isItemSuspendedInRouting && fulfillmentConfiguration && productConfiguraton && (
              <Button
                variant={isPickedOption ? 'default' : 'primary'}
                className="pickMeButton"
                onClick={() =>
                  setPickedOption({
                    chosenFCUrl: fulfillmentConfiguration,
                    chosenProductUrl: productConfiguraton.href,
                  })
                }
              >
                {isPickedOption ? 'Picked!' : 'Pick me!'}
              </Button>
            )}
          </div>
          {option._uiLinks?.productManager && (
            <>
              <div css={headerStyles}>Version</div>
              <div>
                <a href={option._uiLinks.productManager.href} target="_blank" rel="noreferrer">
                  {option._uiLinks.productManager.version}
                </a>
              </div>
            </>
          )}
          {option.productAttribute && (
            <>
              <div css={headerStyles}>Attributes</div>
              <div>{option.productAttribute}</div>
            </>
          )}
          {productConfiguraton && (
            <>
              <div css={headerStyles}>Product Configuration</div>
              <div>
                <a href={productConfiguraton.href} target="_blank" rel="noreferrer">
                  View Configuration
                </a>
              </div>
            </>
          )}
          <>
            <div css={headerStyles}>Fulfillment Configuration</div>
            {option._uiLinks?.fulfillmentConfigurationLink ? (
              <div>
                <a
                  href={option._uiLinks.fulfillmentConfigurationLink.href}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="fulfillment-config-link"
                >
                  {option.fulfillerName || option.fulfillerId} (Version{' '}
                  {option._uiLinks.fulfillmentConfigurationLink.version})
                </a>
              </div>
            ) : (
              <div data-testid="no-fulfillment-config-link">{option.fulfillerName || option.fulfillerId}</div>
            )}
          </>
        </div>
      </div>
      {shipmentPlans && shipmentPlans.length > 0 ? (
        <ShipmentPlanViewer
          accessToken={accessToken}
          environment={environment}
          shipmentPlanUrl={shipmentPlans[0].href}
          showBasicInfo={false}
          showCondensedView={true}
          data-testid="shipment-planner-component"
        />
      ) : (
        <div css={noShipmentPlanStyles} data-testid="no-shipment-plan">
          <h3>Shipment Plan</h3>
          <p>No shipment plan available</p>
        </div>
      )}
    </Accordion>
  );
};
