/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, Tooltip } from '@cimpress/react-components';
import { InvalidConsideredOption, Option, isValidOption } from '../../types';

const { alloy } = colors;

const rankStyle = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${alloy};
  min-height: 6.4rem;
  min-width: 4.7rem;
  height: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  gap: 0.24rem;
  justify-content: center;

  .rankNumber {
    font-size: 1.6rem;
    font-weight: 700;
  }

  @container (max-width: 500px) {
    padding: 0.4rem 0 0.8rem;
    height: 100%;
    justify-content: center;
  }
`;

const tooltipLinkStyles = css`
  color: #ffffff;
  text-decoration: underline;

  :hover {
    color: #cccccc;
  }
`;

const TooltipContent = () => {
  return (
    <span data-testid="rank-tooltip-message">
      This ranking is based on the rules in the routing configuration for the ordered SKU.{' '}
      <a
        href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/16287072303/How+Routing+Chooses+a+Fulfillment+Option"
        target="_blank"
        rel="noreferrer"
        css={tooltipLinkStyles}
      >
        Learn more about how routing chooses a fulfillment option.
      </a>
    </span>
  );
};

export const Rank = ({ option }: { option: Option | InvalidConsideredOption }) => {
  if (!isValidOption(option) || !option.rank) {
    return (
      <Tooltip
        tooltipInnerStyle={{ maxWidth: '400px', textAlign: 'left' }}
        style={{ alignSelf: 'stretch' }}
        direction="right"
        contents={<TooltipContent />}
        data-testid="rank-tooltip"
      >
        <div css={rankStyle} data-testid="fulfillment-option-rank">
          <span>Rank</span>
          <span className="rankNumber">N/A</span>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      {isValidOption(option) && option.rank && (
        <Tooltip
          tooltipInnerStyle={{ maxWidth: '400px', textAlign: 'left' }}
          style={{ alignSelf: 'stretch' }}
          direction="right"
          contents={<TooltipContent />}
        >
          <div css={rankStyle} className="rank" data-testid="fulfillment-option-rank">
            <span>Rank</span>
            <span className="rankNumber">{option.rank}</span>
          </div>
        </Tooltip>
      )}
    </>
  );
};
