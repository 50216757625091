/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Copy, Label, colors } from '@cimpress/react-components';
import { InvalidConsideredOption, Option } from '../../types';
import { Rank } from './Rank';
import { OptionStatus } from './OptionStatus';
import { get } from 'lodash';
import { copyButtonStyles } from '../../sharedStyles';

const { ocean, slate } = colors;

const accordionHeaderStyles = css`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  gap: 1.6rem;

  .contentGrid {
    display: flex;
    gap: 1.6rem;
    align-items: center;

    @container (max-width: 500px) {
      flex-wrap: wrap;
      gap: 0.8rem;
      padding: 0.8rem 0;

      .labelWrapper {
        margin-left: 0;
      }
    }
  }

  .labelWrapper {
    display: flex;
    gap: 1.2rem;
    margin: 0.8rem;

    @container (max-width: 340px) {
      flex-wrap: wrap;
    }
  }

  .fulfillerLabel,
  .priceLabel {
    color: #ffffff;
    border: none;
    font-size: 1.2rem;
    align-self: center;
  }

  .fulfillerLabel {
    background-color: ${ocean.base};
  }

  .priceLabel {
    background-color: ${slate};
  }
`;

const productManagerLinkStyles = css`
  display: flex;
  gap: 0.4rem;
  min-width: max-content;
  align-items: center;
`;

export const FulfillmentOptionHeader = ({ option }: { option: Option | InvalidConsideredOption }) => {
  const productManagerLink = get(option, ['_uiLinks', 'productManager', 'href']) as string | undefined;

  return (
    <div css={accordionHeaderStyles} data-testid="fulfillment-option-header">
      <Rank option={option} />
      <div className="contentGrid">
        <OptionStatus option={option} />
        {productManagerLink && (
          <div css={productManagerLinkStyles}>
            <a
              href={productManagerLink}
              target="_blank"
              rel="noreferrer"
              onClick={(event) => event.stopPropagation()}
              data-testid="option-pm-link"
            >
              {option.referenceId}{' '}
            </a>
            <Copy
              value={option.referenceId}
              css={copyButtonStyles}
              onClick={(event) => event.stopPropagation()}
              data-testid="copy-button-link"
            >
              <></>
            </Copy>
          </div>
        )}
        {!productManagerLink && (
          <div css={productManagerLinkStyles}>
            {option.referenceId}{' '}
            <Copy
              value={option.referenceId}
              css={copyButtonStyles}
              onClick={(event) => event.stopPropagation()}
              data-testid="copy-button-no-link"
            >
              <></>
            </Copy>
          </div>
        )}
        <div className="labelWrapper">
          {(option.fulfillerName || option.fulfillerId) && (
            // Cast doesn't seem necessary but TS can't narrow the type here for some reason
            <Label text={(option.fulfillerName || option.fulfillerId) as string} className="fulfillerLabel" size="lg" />
          )}
          {option.totalCostUSD && option.totalCostUSD > 0 && (
            <Label
              text={'$' + Number(Math.round(option.totalCostUSD * 100) / 100).toFixed(2)}
              className="priceLabel"
              size="lg"
            />
          )}
        </div>
      </div>
    </div>
  );
};
