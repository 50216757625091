import React from 'react';
import { cx, css } from '@emotion/css';
import cvar from './theme/cvar';
import { Close } from './internal';
import { PublicComponentProps } from './types';

export interface TagProps extends PublicComponentProps {
  /** Label to render within the tag. If not provided, value will be shown */
  label?: React.ReactNode;
  /** Function to be called when the x to remove the tag is clicked */
  onRemoveClick?: (value?: React.ReactNode) => void;
  /** Determines whether to show an 'x' at the right hand side of the component to indicate the tag can be removed */
  removable?: boolean;
  /** The size of the tag */
  size?: 'sm' | 'default' | 'lg';
  /** Value represented by the tag */
  value?: React.ReactNode;
  /** Tag variant */
  variant?: 'default' | 'counter';
}

const tagBase = css`
  display: inline-flex;
  align-items: center;
  background-color: ${cvar('color-background')};
  border: 1px solid ${cvar('color-border-default')};
  border-radius: ${cvar('spacing-24')};
  padding: calc((${cvar('spacing-24')} - ${cvar('line-height-default')} - 2px) / 2) ${cvar('spacing-12')};
  font: ${cvar('text-label-default')};
  color: ${cvar('color-text-label')};
  &:hover {
    border: 1px solid ${cvar('color-button-primary')};
  }
`;

const tagLarge = css`
  font: ${cvar('text-label-large')};
  padding: calc((${cvar('spacing-32')} - ${cvar('line-height-large')} - 2px) / 2) ${cvar('spacing-16')};
  border-radius: ${cvar('spacing-32')};
`;

const tagSmall = css`
  font: ${cvar('text-label-small')};
  padding: calc((${cvar('spacing-16')} - ${cvar('line-height-small')} - 2px) / 2) ${cvar('spacing-8')};
  border-radius: ${cvar('spacing-16')};
`;

const counterBase = css`
  display: inline-flex;
  align-items: center;
  background-color: ${cvar('color-button-primary')};
  border-radius: 4px;
  padding: calc((${cvar('spacing-24')} - ${cvar('line-height-default')}) / 2) ${cvar('spacing-12')};
  font: ${cvar('text-label-default')};
  color: ${cvar('color-text-inverse')};
`;

const counterLarge = css`
  font: ${cvar('text-label-large')};
  padding: calc((${cvar('spacing-32')} - ${cvar('line-height-large')}) / 2) ${cvar('spacing-16')};
`;

const counterSmall = css`
  font: ${cvar('text-label-small')};
  padding: calc((${cvar('spacing-16')} - ${cvar('line-height-small')}) / 2) ${cvar('spacing-8')};
`;

export function Tag(props: TagProps) {
  const handleClick = () => {
    props.onRemoveClick && props.onRemoveClick(props.value);
  };

  const {
    className = '',
    label,
    onRemoveClick,
    removable,
    size: tagSize = 'default',
    value,
    variant = 'default',
    ...rest
  } = props; // eslint-disable-line no-unused-vars

  const buttonIconSize = {
    sm: 8,
    default: 12,
    lg: 16,
  };
  const buttonSize = {
    sm: cvar('spacing-8'),
    default: cvar('spacing-12'),
    lg: cvar('spacing-16'),
  };
  const removeButton = css`
    height: ${buttonSize[tagSize]};
    width: ${buttonSize[tagSize]};
    color: ${cvar('color-button-primary')};
    margin-left: ${cvar('spacing-16')};
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  `;
  const isTag = variant === 'default';
  const isCounter = variant === 'counter';
  const tagClass = cx(
    'crc-tag',
    {
      [tagBase]: isTag,
      [tagLarge]: isTag && tagSize === 'lg',
      [tagSmall]: isTag && tagSize === 'sm',
      [counterBase]: isCounter,
      [counterLarge]: isCounter && tagSize === 'lg',
      [counterSmall]: isCounter && tagSize === 'sm',
    },
    className,
  );
  return (
    <span className={tagClass} {...rest}>
      {label !== undefined ? label : value}
      {removable && (
        <button className={removeButton} onClick={handleClick}>
          <Close color={cvar('color-button-primary')} width={`${buttonIconSize[tagSize]}px`} aria-label="Remove tag" />
        </button>
      )}
    </span>
  );
}
