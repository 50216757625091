import React, { HTMLProps } from 'react';
import * as CSS from 'csstype';
import { cx } from '@emotion/css';
import { PublicComponentProps } from './types';

export interface FlexBoxProps extends HTMLProps<HTMLDivElement>, PublicComponentProps {
  alignContent?: CSS.Property.AlignContent;
  alignItems?: CSS.Property.AlignItems;
  alignSelf?: CSS.Property.AlignSelf;
  flex?: CSS.Property.Flex<string | number>;
  flexBasis?: CSS.Property.FlexBasis<string | number>;
  flexDirection?: CSS.Property.FlexDirection;
  flexFlow?: CSS.Property.FlexFlow;
  flexWrap?: CSS.Property.FlexWrap;
  justifyContent?: CSS.Property.JustifyContent;
}

export const FlexBox = ({
  style,
  children,
  alignContent,
  alignItems = 'center',
  alignSelf,
  flex,
  flexBasis,
  justifyContent = 'space-between',
  flexDirection,
  flexFlow,
  flexWrap,
  className,
  ...restProps
}: FlexBoxProps) => {
  const flexBoxStyles = {
    display: 'flex',
    alignContent,
    alignItems,
    alignSelf,
    flex,
    flexBasis,
    flexDirection,
    flexFlow,
    flexWrap,
    justifyContent,
  };

  // shorthand properties e.g, `flexFlow = undefined` overwrite the normal properties, e.g., `flexDirection`
  // therefore filtering all `undefined` values before passing them to component styles
  Object.keys(flexBoxStyles).forEach(k => {
    if (!flexBoxStyles[k]) {
      delete flexBoxStyles[k];
    }
  });

  return (
    <div className={cx('crc-flexbox', className)} style={{ ...flexBoxStyles, ...style }} {...restProps}>
      {children}
    </div>
  );
};
